import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

import ThreeSixtyImage from "images/360-black-18dp.svg";
import AirplaneImage from "images/airplanemode_active-black-18dp.svg";
import BusinessCenterImage from "images/business_center-black-18dp.svg";
import DomainImage from "images/domain-black-18dp.svg";
import MettingRoomImage from "images/meeting_room-black-18dp.svg";
import MonetixationImage from "images/monetization_on-black-18dp.svg";
import SecurityImage from "images/security-black-18dp.svg";

const Container = tw(ContainerBase)`bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl text-primary-500`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row flex-wrap max-w-screen-md justify-between mx-auto`
const StatValue = tw.div`text-xl font-medium`

const Features = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap md:justify-center`;
const Feature = tw.div`mt-10 lg:mt-4 flex md:items-center flex-col md:ml-8 last:mr-2 md:w-1/4 tracking-wide font-bold leading-none`;

const FeatureDescription = tw.div`mt-4 md:text-left text-gray-100 leading-relaxed`;
const Featureimage = tw.div``;

export default ({
  
  features = null,
  subheading = "",
  heading = "VICENTE BELTRÁN Y CIA",
  description = "",
}) => {
  const defaultFeatures = [
    {
      imageSrc: MettingRoomImage,
      description: "INVERSIONES EXTRANJERAS EN COLOMBIA",
    },
    {
      imageSrc: SecurityImage,
      description: "DEFENSA POR INFRACCIONES DE CAMBIO",
    },
    {
      imageSrc: ThreeSixtyImage,
      description: "IMPORTACIONES Y EXPORTACIONES DE BIENES",
    },
    {
      imageSrc: DomainImage,
      description: "ENDEUDAMIENTO EN MONEDA EXTRANJERA",
    },
    {
      imageSrc: AirplaneImage,
      description: "INVERSIONES COLOMBIANAS EN EL EXTERIOR",
    },
    {
      imageSrc: MonetixationImage,
      description: "CAMBIOS INTERNACIONALES Y TRANSFERENCIAS EN MONEDA EXTRANJERA",
    },{
      imageSrc: BusinessCenterImage,
      description: "CONTRATOS DE ASISTENCIA TÉCNICA, REGALÍAS, SERVICIOS TÉCNICOS Y DE COLABORACIÓN EMPRESARIAL Y SUS EFECTOS TRIBUTARIOS",
    },
  ];

  if (!features) features = defaultFeatures;



  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          <StatValue>Es una firma de abogados que presta servicios de asesoría y gestión legal en comercio exterior con énfasis en cambios internacionales e inversiones de capital extranjero en el país e inversiones de capitales colombianos en el exterior.</StatValue>
        </StatsContainer>
        <Features>
          {features.map((feature, index) => (
            <Feature key={index}>
              <FeatureDescription style={{display: "inherit"}}>
                <Featureimage style={{marginRight: "10px"}}><img src={feature.imageSrc } alt={feature.description} style={{width: "40px", height: "40px", maxWidth: "40px"}} /></Featureimage>
                {feature.description}
              </FeatureDescription>
            </Feature>
          ))}
        </Features>
      </ContentWithPaddingXl>
    </Container>
  );
};
