import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "./components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "./components/features/ThreeColWithSideImage.js";
import FeatureStats from "./components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Footer from "./components/footers/MiniCenteredFooter.js";

const HighlightedText = tw.span`text-secondary-600`

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features 
        heading={<>Nuestro portafolio de clientes <HighlightedText>está compuesto por:</HighlightedText></>}
      />
      <FeatureStats />
      <Footer />
    </AnimationRevealPage>
  );
}
