import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-4`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw` inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <SocialLinksContainer>
            <SocialLink>
              Email: abogado@vicentebeltran.com
            </SocialLink>
            <SocialLink>
              Telefono: 2161449 
            </SocialLink>
            <SocialLink>
              Celular: 310 8060275
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Vicente Beltrán Y CIA 2020.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
